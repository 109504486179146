
  .container {
    position: relative;
    height: 100%;

    .btns {
      position: absolute;
      left: 0;
      bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-form-inline .ant-form-item {
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-right: 100px;
  }

  .plate-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 10px 0;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 22px;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: -8px;
      width: 2.5px;
      height: 12px;
      border-radius: 3px;
      background: #0e7ed5;
    }
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;